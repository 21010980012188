import 'styles/app.scss';

const App = () => {
  return (
    <div className="app">
      <div className="text">See you soon ...</div>
      <div className="footer">
        © 2024 RITAS
      </div>
    </div>
  );
}

export default App;
